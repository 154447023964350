import React from 'react';
import { LegalMapKaysData } from 'helpers/constants/legal-map/legal-map-key-data';
import { TLegalMapProps } from 'helpers/types/molecules/legal-map/legal-map';
import Map from './map';
import MedicalKey from './map/medical-key';

const LegalMap: React.FC<TLegalMapProps> = ({
  onClick,
  onMouseOver,
  onMouseOut,
  styles,
  showLabel = true,
  statesData,
  labelsData,
  containerStyle,
  removeMinHeight = false,
  keysData = LegalMapKaysData,
}) => {
  const handleStateClick = (state: string) => {
    if (onClick) {
      onClick(state);
    }
    const sectionId = `toc_${state.toLowerCase()}`;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offsetTop = sectionElement.offsetTop;

      const customOffset = 170;

      window.scrollTo({
        top: offsetTop - customOffset,
        behavior: 'auto',
      });
    }
  };

  return (
    <div className="flex flex-col px-5 md:px-10 2xl:px-[60px]">
      <MedicalKey {...keysData} />
      <Map
        showLabel={showLabel}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        styles={styles}
        statesData={statesData}
        labelsData={labelsData}
        containerStyle={containerStyle}
        removeMinHeight={removeMinHeight}
        onClick={(state) => handleStateClick(state)}
      />
    </div>
  );
};

export default LegalMap;
