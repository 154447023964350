import { useMemo } from 'react';
import { LegalMapLablesData } from 'helpers/constants/legal-map/legal-map-labels-data';
import { DataProps } from 'helpers/types/molecules/legal-map/legal-map';
import { buildElement } from 'helpers/utils/legal-map';
import { DefaultG, MapWhiteTG } from './styles';

type TProps = {
  labelsData: DataProps[];
};

const Labels = ({ labelsData = LegalMapLablesData }: TProps) => {
  const labelsContent = useMemo(
    () =>
      labelsData &&
      labelsData.map((data) => {
        switch (data.type) {
          case 'MapWhiteTG':
            return buildElement({ state: data, Element: MapWhiteTG });
          case 'g':
            return buildElement({ state: data, Element: DefaultG });
          default:
            return null;
        }
      }),
    [labelsData],
  );

  return <g id="labels">{labelsContent}</g>;
};

export default Labels;
