import tw from 'tailwind-styled-components';
const styles = `
  stroke-white
  cursor-pointer
  transition-[fill]
  duration-[0.5s]
  ease-in
`;
export const RecreationalG = tw.g`
  fill-leaf
  hover:fill-resin
  ${() => styles}
`;

export const RecreationalPath = tw.path`
  fill-leaf
  hover:fill-resin
  ${() => styles}
`;

export const RecreationalPolyline = tw.polyline`
  fill-leaf
  hover:fill-resin
  ${() => styles}
`;

/**** Full Medical Use ****/
export const MedicalG = tw.g`
  fill-kief
  hover:fill-resin
  ${() => styles}
`;

export const MedicalPath = tw.path`
  fill-kief
  hover:fill-resin
  ${() => styles}
`;

export const MedicalPolyline = tw.polyline`
  fill-kief
  hover:fill-resin
  ${() => styles}
`;

/**** Limited Medical Use ****/
export const LimitedPath = tw.path`
  fill-kief-900
  hover:fill-resin
  ${() => styles}
`;

export const LimitedPolyline = tw.polyline`
   fill-kief-900
   hover:fill-resin
   ${() => styles}
`;

/**** Not Legal ****/
export const NotLegalPath = tw.path`
   fill-grey-200
  hover:fill-resin
  ${() => styles}
`;
