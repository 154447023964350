import { TLegalMapProps, DataProps } from 'helpers/types/molecules/legal-map/legal-map';
import Labels from './labels';
import States from './states';
import { MapContainer, MapSvg } from './styles';

const Map = ({
  onClick,
  onMouseOver,
  onMouseOut,
  styles,
  showLabel,
  location,
  statesData,
  labelsData,
  containerStyle,
  mapSvgStyle,
  removeMinHeight,
}: TLegalMapProps) => {
  const onMapClick = (e: any) => {
    if (onClick) {
      const state: string = e.target.id;
      if (state !== 'United_States' && state) onClick(state);
    }
  };

  const onMapMouseOver = (e: any) => {
    if (onMouseOver) {
      const state: string = e.target.id;
      if (state !== 'United_States' && state) onMouseOver(state);
    }
  };

  const onMapMouseOut = (e: any) => onMouseOut && onMouseOut(e);

  return (
    <MapContainer $style={containerStyle as string}>
      <MapSvg
        $style={mapSvgStyle as string}
        $removeMinHeight={removeMinHeight}
        width="600"
        height="400"
        viewBox="0 0 600 400"
        overflow="visible"
        enableBackground="new 0 0 600 400"
        onClick={onMapClick}
        onMouseOver={onMapMouseOver}
        onMouseOut={onMapMouseOut}
        data-testid="map"
      >
        <rect id="background" fill="none" width="600" height="400" />
        <g id="Layer_2" data-name="Layer 2">
          <g id="United_States" data-name="United States">
            <States styles={styles} statesData={statesData} location={location} />
            {showLabel && (
              <>
                <Labels labelsData={labelsData as DataProps[]} />
                <g id="lines" data-testid="lines-map">
                  <line fill="none" stroke="#000000" x1="535.375" y1="136.125" x2="543" y2="137.25" />
                  <line fill="none" stroke="#000000" x1="539.125" y1="154.25" x2="530.125" y2="155.875" />
                  <line fill="none" stroke="#000000" x1="538.125" y1="168.625" x2="512.563" y2="151.313" />
                  <line fill="none" stroke="#000000" x1="560.375" y1="117.375" x2="548.125" y2="109.5" />
                  <line fill="none" stroke="#000000" x1="571.375" y1="111.625" x2="558.375" y2="104.75" />
                  <line fill="none" stroke="#000000" x1="569.75" y1="90.5" x2="556.5" y2="96.625" />
                  <line fill="none" stroke="#000000" x1="524.625" y1="56.5" x2="536.875" y2="70" />
                  <line fill="none" stroke="#000000" x1="540.5" y1="50.875" x2="552.5" y2="79.5" />
                </g>
              </>
            )}
          </g>
        </g>
      </MapSvg>
    </MapContainer>
  );
};

export default Map;
