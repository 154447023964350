import tw from 'tailwind-styled-components';
type MapStyle = {
  $style: string;
  $removeMinHeight?: boolean;
};

export const MapContainer = tw.div<MapStyle>`
  h-auto
  overflow-hidden
  mx-0
  pt-8
  md:pt-5 
  2xl:pt-6
  flex
  self-start
  ${(p) => p.$style}
`;

export const MapSvg = tw.svg<MapStyle>`
  block
  w-full
  overflow-hidden
  my-0
  mx-auto
  ${(p) => (!p.$removeMinHeight ? `sm:h-[233px] md:h-[510px] 2xl:h-[788px]` : '')}
  ${(p) => p.$style}
`;
