export const LegalMapLablesData = [
  {
    name: 'CA',
    type: 'MapWhiteTG',
    data: [
      'M45.281,177.586v1.109c-0.771-0.38-1.479-0.57-2.126-0.57c-0.831,0-1.53,0.269-2.099,0.807    c-0.569,0.537-0.853,1.198-0.853,1.98c0,0.798,0.291,1.464,0.873,1.998c0.582,0.534,1.31,0.801,2.186,0.801    c0.636,0,1.309-0.2,2.02-0.602v1.117c-0.647,0.344-1.349,0.516-2.105,0.516c-1.108,0-2.055-0.375-2.841-1.126    c-0.786-0.75-1.179-1.655-1.179-2.715c0-1.04,0.386-1.931,1.159-2.672c0.773-0.741,1.704-1.112,2.793-1.112    C43.809,177.117,44.533,177.274,45.281,177.586z',
      'M49.114,177.071l3.379,7.43h-1.018l-0.835-1.836h-3.033l-0.825,1.836h-1.016L49.114,177.071z M49.114,179.308    l-1.142,2.544h2.299L49.114,179.308z',
    ],
  },
  {
    name: 'WA',
    type: 'MapWhiteTG',
    data: [
      'M75.738,31.321l2.301,5.291l2.159-5.002h1.013l-3.168,7.367l-2.307-5.304l-2.303,5.304l-3.168-7.367h1.009l2.171,5.05    L75.738,31.321z',
      'M84.115,31.321l3.379,7.43h-1.018l-0.835-1.836h-3.033l-0.825,1.836h-1.016L84.115,31.321z M84.115,33.558l-1.142,2.544    h2.299L84.115,33.558z',
    ],
  },
  {
    name: 'OR',
    type: 'MapWhiteTG',
    data: [
      'M53.703,80.379c0-1.033,0.389-1.918,1.167-2.655c0.778-0.738,1.713-1.106,2.805-1.106c1.066,0,1.984,0.372,2.755,1.115    c0.771,0.743,1.156,1.625,1.156,2.647c0,1.082-0.381,1.996-1.142,2.743c-0.761,0.747-1.695,1.12-2.803,1.12    c-1.092,0-2.021-0.376-2.788-1.128C54.087,82.362,53.703,81.45,53.703,80.379z M60.547,80.424c0-0.79-0.284-1.455-0.853-1.996    c-0.568-0.541-1.267-0.811-2.097-0.811c-0.789,0-1.465,0.275-2.027,0.825c-0.563,0.55-0.844,1.21-0.844,1.981    c0,0.782,0.289,1.443,0.866,1.984s1.285,0.811,2.123,0.811c0.77,0,1.435-0.276,1.993-0.828    C60.268,81.839,60.547,81.183,60.547,80.424z',
      'M63.015,76.844h1.551c0.772,0,1.4,0.192,1.886,0.575c0.485,0.384,0.728,0.875,0.728,1.473    c0,0.479-0.157,0.902-0.471,1.269c-0.313,0.367-0.803,0.625-1.467,0.774L67.32,84h-1.121l-1.988-2.945h-0.219V84h-0.977V76.844z     M63.991,77.711v2.492c1.442,0.03,2.164-0.387,2.164-1.252c0-0.303-0.084-0.551-0.253-0.744c-0.169-0.192-0.394-0.324-0.675-0.393    C64.947,77.746,64.535,77.711,63.991,77.711z',
    ],
  },
  {
    name: 'NV',
    type: 'MapWhiteTG',
    data: [
      'M76.063,143.938l5.297,5.434v-5.152h0.977v7.484l-5.297-5.411v5.083h-0.977V143.938z',
      'M83.194,144.235h1.031l2.241,5.072l2.27-5.072h1.036l-3.316,7.383L83.194,144.235z',
    ],
  },
  {
    name: 'IL',
    type: 'MapWhiteTG',
    data: ['M376.477,157.858h0.969v7.156h-0.969V157.858z', 'M379.162,157.858h0.969v6.188h2.328v0.969h-3.297V157.858z'],
  },
  {
    name: 'VT',
    type: 'g',
    data: [
      'M513.336,43.922h1.031l2.239,5.072l2.271-5.072h1.036l-3.316,7.383L513.336,43.922z',
      'M520.446,43.906h4.383v0.977h-1.711v6.18h-0.97v-6.18h-1.702V43.906z',
    ],
  },
  {
    name: 'VA',
    type: 'MapWhiteTG',
    data: [
      'M494.031,174.226h1.03l2.24,5.072l2.271-5.072h1.035l-3.315,7.383L494.031,174.226z',
      'M503.443,173.937l3.38,7.43h-1.019l-0.835-1.836h-3.033l-0.823,1.836h-1.018L503.443,173.937z M503.443,176.175    l-1.142,2.544h2.299L503.443,176.175z',
    ],
  },
  {
    name: 'AL',
    type: 'MapWhiteTG',
    data: [
      'M413.668,252.32l3.379,7.431h-1.019l-0.835-1.836h-3.032l-0.825,1.836h-1.017L413.668,252.32z M413.668,254.558    l-1.144,2.544h2.3L413.668,254.558z',
      'M418.087,252.594h0.969v6.188h2.328v0.97h-3.297V252.594z',
    ],
  },
  {
    name: 'AK',
    type: 'MapWhiteTG',
    data: [
      'M69.418,307.571l3.379,7.43h-1.018l-0.835-1.836h-3.033l-0.825,1.836H66.07L69.418,307.571z M69.418,309.808l-1.142,2.544    h2.299L69.418,309.808z',
      'M73.696,307.844h0.977v2.908l3.022-2.908h1.368l-3.452,3.322L79.087,315h-1.251l-3.163-3.489V315h-0.977V307.844z',
    ],
  },
  {
    name: 'HI',
    type: 'g',
    data: [
      'M183.781,350.511h0.977v2.75h3.414v-2.75h0.969v7.156h-0.969v-3.438h-3.414v3.438h-0.977V350.511z',
      'M190.673,350.511h0.969v7.156h-0.969V350.511z',
    ],
  },
  {
    name: 'UT',
    type: 'MapWhiteTG',
    data: [
      'M132.211,160.36h0.977v4.395c0,0.651,0.156,1.154,0.469,1.51c0.313,0.355,0.755,0.533,1.329,0.533    c0.569,0,1.012-0.164,1.329-0.491c0.316-0.327,0.475-0.788,0.475-1.383v-4.563h0.977v4.497c0,0.876-0.25,1.576-0.75,2.1    c-0.5,0.524-1.165,0.786-1.997,0.786c-0.85,0-1.53-0.266-2.042-0.797c-0.511-0.531-0.767-1.315-0.767-2.352V160.36z',
      'M138.759,160.344h4.383v0.977h-1.711v6.18h-0.969v-6.18h-1.703V160.344z',
    ],
  },
  {
    name: 'NM',
    type: 'MapWhiteTG',
    data: [
      'M184.188,235.063l5.297,5.434v-5.151h0.977v7.483l-5.297-5.41v5.083h-0.977V235.063z',
      'M197.807,235.047l1.957,7.453h-0.955l-1.189-4.563l-2.032,4.836l-2.042-4.856l-1.198,4.583h-0.952l1.949-7.453l2.24,5.329    L197.807,235.047z',
    ],
  },
  {
    name: 'AZ',
    type: 'MapWhiteTG',
    data: [
      'M125.407,225.32l3.379,7.431h-1.018l-0.835-1.836H123.9l-0.825,1.836h-1.016L125.407,225.32z M125.407,227.558    l-1.142,2.544h2.299L125.407,227.558z',
      'M129.475,225.594h4.836l-3.423,6.188h3.353v0.97h-4.938l3.41-6.181h-3.238V225.594L129.475,225.594z',
    ],
  },
  {
    name: 'SD',
    type: 'MapWhiteTG',
    data: [
      'M261.742,96.764l-0.792,0.635c-0.209-0.218-0.399-0.38-0.569-0.488c-0.17-0.107-0.416-0.161-0.737-0.161    c-0.352,0-0.636,0.08-0.855,0.239c-0.219,0.159-0.328,0.364-0.328,0.614c0,0.217,0.095,0.409,0.286,0.576    c0.19,0.167,0.516,0.349,0.975,0.546c0.459,0.197,0.817,0.381,1.074,0.551c0.256,0.17,0.466,0.359,0.63,0.564    c0.165,0.206,0.287,0.423,0.367,0.651c0.081,0.229,0.121,0.47,0.121,0.725c0,0.595-0.216,1.103-0.649,1.522    c-0.433,0.419-0.956,0.629-1.57,0.629c-0.6,0-1.129-0.164-1.588-0.491c-0.459-0.328-0.813-0.819-1.06-1.474l0.977-0.277    c0.356,0.808,0.903,1.211,1.639,1.211c0.356,0,0.653-0.106,0.89-0.319c0.236-0.213,0.354-0.481,0.354-0.806    c0-0.194-0.062-0.389-0.187-0.585c-0.124-0.195-0.278-0.357-0.463-0.484c-0.185-0.126-0.477-0.273-0.877-0.439    c-0.4-0.167-0.708-0.318-0.924-0.455c-0.216-0.137-0.403-0.299-0.561-0.488c-0.158-0.189-0.274-0.377-0.347-0.564    c-0.073-0.187-0.11-0.387-0.11-0.601c0-0.513,0.206-0.95,0.618-1.311c0.412-0.361,0.912-0.542,1.5-0.542    c0.39,0,0.785,0.084,1.186,0.253C261.143,96.163,261.476,96.42,261.742,96.764z',
      'M263.133,95.969h1.734c1.374,0,2.396,0.363,3.065,1.09c0.669,0.727,1.005,1.556,1.005,2.488    c0,0.94-0.346,1.771-1.039,2.494c-0.693,0.723-1.699,1.084-3.02,1.084h-1.746L263.133,95.969L263.133,95.969z M264.11,96.922    v5.234h0.056c0.727,0,1.273-0.033,1.641-0.098c0.367-0.065,0.715-0.208,1.045-0.43c0.33-0.222,0.592-0.514,0.787-0.879    c0.194-0.365,0.292-0.767,0.292-1.205c0-0.551-0.155-1.049-0.466-1.494s-0.683-0.745-1.115-0.898    c-0.433-0.154-1.014-0.23-1.745-0.23H264.11L264.11,96.922z',
    ],
  },
  {
    name: 'NE',
    type: 'g',
    data: [
      'M267.438,140.313l5.297,5.434v-5.152h0.977v7.484l-5.297-5.411v5.083h-0.977V140.313z',
      'M275.092,140.594h3.96v0.977h-2.992v1.781h2.992v0.969h-2.992v2.461h2.992v0.969h-3.96V140.594z',
    ],
  },
  {
    name: 'IA',
    type: 'MapWhiteTG',
    data: [
      'M328.977,131.344h0.969v7.156h-0.969V131.344z',
      'M334.189,131.071l3.379,7.43h-1.018l-0.835-1.836h-3.033l-0.823,1.836h-1.018L334.189,131.071z M334.189,133.308    l-1.143,2.544h2.299L334.189,133.308z',
    ],
  },
  {
    name: 'KS',
    type: 'g',
    data: [
      'M275.764,181.358h0.977v2.909l3.022-2.909h1.368l-3.452,3.322l3.476,3.834h-1.251l-3.163-3.489v3.489h-0.977V181.358    L275.764,181.358z',
      'M286.476,182.153l-0.792,0.635c-0.209-0.218-0.399-0.38-0.569-0.488c-0.17-0.107-0.416-0.161-0.737-0.161    c-0.352,0-0.637,0.08-0.855,0.239c-0.219,0.159-0.328,0.364-0.328,0.614c0,0.217,0.095,0.409,0.286,0.576s0.516,0.349,0.976,0.546    c0.46,0.197,0.817,0.381,1.073,0.551c0.256,0.17,0.466,0.359,0.631,0.564c0.165,0.206,0.287,0.423,0.367,0.651    c0.08,0.229,0.121,0.47,0.121,0.725c0,0.595-0.216,1.103-0.649,1.522c-0.433,0.419-0.957,0.629-1.571,0.629    c-0.6,0-1.128-0.164-1.587-0.491c-0.459-0.328-0.812-0.819-1.06-1.474l0.977-0.277c0.356,0.808,0.903,1.211,1.638,1.211    c0.357,0,0.653-0.106,0.89-0.319c0.237-0.213,0.355-0.481,0.355-0.806c0-0.194-0.062-0.389-0.187-0.585    c-0.124-0.195-0.279-0.357-0.463-0.484c-0.185-0.126-0.477-0.273-0.876-0.439c-0.4-0.167-0.708-0.318-0.924-0.455    c-0.216-0.137-0.403-0.299-0.561-0.488c-0.158-0.189-0.274-0.377-0.347-0.564c-0.073-0.187-0.11-0.387-0.11-0.601    c0-0.513,0.206-0.95,0.618-1.311c0.412-0.361,0.912-0.542,1.5-0.542c0.39,0,0.785,0.084,1.186,0.253    C285.877,181.552,286.21,181.809,286.476,182.153z',
    ],
  },
  {
    name: 'TX',
    type: 'MapWhiteTG',
    data: [
      'M267.313,279.594h4.383v0.977h-1.711v6.181h-0.969v-6.181h-1.703V279.594z',
      'M272.506,279.594h1.076l1.598,2.609l1.58-2.609h1.09l-2.125,3.512l2.188,3.646h-1.076l-1.655-2.749l-1.664,2.749h-1.09    l2.209-3.649L272.506,279.594z',
    ],
  },
  {
    name: 'LA',
    type: 'MapWhiteTG',
    data: [
      'M342.75,276.094h0.969v6.188h2.328v0.97h-3.297V276.094z',
      'M349.701,275.82l3.38,7.431h-1.019l-0.834-1.836h-3.033l-0.824,1.836h-1.018L349.701,275.82z M349.701,278.058    l-1.143,2.544h2.301L349.701,278.058z',
    ],
  },
  {
    name: 'MN',
    type: 'MapWhiteTG',
    data: [
      'M318.137,79.547L320.094,87h-0.954l-1.19-4.562l-2.03,4.835l-2.041-4.856L312.68,87h-0.951l1.948-7.453l2.24,5.329    L318.137,79.547z',
      'M321.054,79.563l5.297,5.434v-5.152h0.978v7.484l-5.297-5.411V87h-0.978V79.563z',
    ],
  },
  {
    name: 'MO',
    type: 'MapWhiteTG',
    data: [
      'M343.887,183.797l1.957,7.453h-0.955l-1.189-4.562l-2.03,4.835l-2.041-4.856l-1.199,4.583h-0.95l1.948-7.453l2.24,5.329    L343.887,183.797z',
      'M346.57,187.629c0-1.033,0.389-1.918,1.166-2.655c0.778-0.738,1.713-1.106,2.807-1.106c1.064,0,1.982,0.372,2.755,1.115    c0.771,0.743,1.155,1.625,1.155,2.647c0,1.082-0.381,1.996-1.143,2.743c-0.762,0.747-1.695,1.12-2.803,1.12    c-1.092,0-2.021-0.376-2.788-1.128S346.57,188.7,346.57,187.629z M353.412,187.674c0-0.79-0.283-1.455-0.853-1.996    s-1.268-0.811-2.097-0.811c-0.789,0-1.465,0.275-2.027,0.825c-0.563,0.55-0.844,1.21-0.844,1.981c0,0.782,0.289,1.443,0.866,1.984    s1.284,0.811,2.122,0.811c0.771,0,1.435-0.276,1.994-0.828C353.134,189.089,353.412,188.433,353.412,187.674z',
    ],
  },
  {
    name: 'TN',
    type: 'MapWhiteTG',
    data: [
      'M410.395,214.594h4.383v0.977h-1.711v6.181h-0.969v-6.181h-1.703V214.594z',
      'M415.807,214.313l5.297,5.434v-5.151h0.978v7.483l-5.297-5.41v5.083h-0.978V214.313z',
    ],
  },
  {
    name: 'OK',
    type: 'MapWhiteTG',
    data: [
      'M287.698,228.134c0-1.032,0.389-1.918,1.167-2.655c0.778-0.738,1.713-1.105,2.805-1.105c1.066,0,1.984,0.371,2.755,1.113    c0.771,0.744,1.156,1.627,1.156,2.647c0,1.082-0.381,1.996-1.142,2.743c-0.761,0.747-1.695,1.119-2.803,1.119    c-1.092,0-2.021-0.375-2.788-1.127C288.082,230.115,287.698,229.205,287.698,228.134z M294.542,228.179    c0-0.79-0.284-1.455-0.853-1.995c-0.568-0.541-1.267-0.813-2.097-0.813c-0.789,0-1.465,0.275-2.027,0.826    c-0.563,0.549-0.844,1.211-0.844,1.98c0,0.782,0.289,1.443,0.866,1.982c0.578,0.541,1.285,0.813,2.123,0.813    c0.77,0,1.435-0.275,1.993-0.828C294.263,229.594,294.542,228.938,294.542,228.179z',
      'M296.862,224.599h0.977v2.909l3.022-2.909h1.367l-3.452,3.322l3.476,3.834h-1.251l-3.163-3.489v3.489h-0.977V224.599    L296.862,224.599z',
    ],
  },
  {
    name: 'MI',
    type: 'MapWhiteTG',
    data: [
      'M423.887,113.297l1.957,7.453h-0.954l-1.189-4.562l-2.031,4.835l-2.041-4.856l-1.198,4.583h-0.952l1.949-7.453l2.24,5.329    L423.887,113.297z',
      'M426.843,113.594h0.969v7.156h-0.969V113.594z',
    ],
  },
  {
    name: 'MS',
    type: 'MapWhiteTG',
    data: [
      'M380.887,257.047l1.957,7.453h-0.954l-1.19-4.563l-2.03,4.836l-2.041-4.856l-1.198,4.583h-0.951l1.948-7.453l2.24,5.329    L380.887,257.047z',
      'M388.234,258.139l-0.793,0.635c-0.209-0.218-0.397-0.381-0.567-0.488c-0.171-0.105-0.416-0.159-0.738-0.159    c-0.351,0-0.636,0.079-0.854,0.237c-0.219,0.16-0.328,0.365-0.328,0.615c0,0.217,0.096,0.408,0.287,0.575    c0.189,0.167,0.516,0.349,0.976,0.546c0.459,0.197,0.817,0.381,1.073,0.551c0.256,0.172,0.466,0.359,0.631,0.564    c0.164,0.207,0.286,0.424,0.367,0.652c0.08,0.229,0.119,0.47,0.119,0.725c0,0.596-0.217,1.102-0.648,1.521    c-0.434,0.418-0.956,0.629-1.57,0.629c-0.6,0-1.129-0.164-1.588-0.491s-0.813-0.819-1.06-1.474l0.977-0.277    c0.356,0.808,0.902,1.211,1.64,1.211c0.355,0,0.652-0.105,0.89-0.318c0.235-0.213,0.354-0.482,0.354-0.807    c0-0.193-0.063-0.389-0.188-0.585c-0.124-0.195-0.278-0.356-0.463-0.483c-0.186-0.127-0.477-0.273-0.877-0.439    c-0.399-0.166-0.708-0.317-0.924-0.454c-0.217-0.138-0.403-0.3-0.563-0.488s-0.273-0.377-0.347-0.563    c-0.074-0.188-0.11-0.388-0.11-0.602c0-0.513,0.206-0.949,0.618-1.311s0.912-0.543,1.5-0.543c0.392,0,0.785,0.084,1.187,0.254    C387.634,257.537,387.967,257.795,388.234,258.139z',
    ],
  },
  {
    name: 'FL',
    type: 'MapWhiteTG',
    data: [
      'M483.922,316.344h3.961v0.978h-2.992v1.78h2.992v0.97h-2.984v3.43h-0.977V316.344z',
      'M489.178,316.344h0.969v6.188h2.328v0.969h-3.297V316.344z',
    ],
  },
  {
    name: 'ND',
    type: 'MapWhiteTG',
    data: [
      'M256.26,53.313l5.297,5.434v-5.152h0.977v7.484l-5.297-5.411v5.083h-0.977V53.313z',
      'M264.001,53.594h1.735c1.373,0,2.395,0.363,3.065,1.09c0.67,0.727,1.005,1.556,1.005,2.488    c0,0.94-0.346,1.771-1.039,2.494c-0.693,0.723-1.699,1.084-3.021,1.084H264L264.001,53.594L264.001,53.594z M264.977,54.547v5.234    h0.056c0.727,0,1.273-0.033,1.641-0.098c0.367-0.065,0.715-0.208,1.045-0.43c0.33-0.222,0.592-0.514,0.787-0.879    c0.195-0.365,0.292-0.767,0.292-1.205c0-0.551-0.156-1.049-0.466-1.494c-0.311-0.445-0.683-0.745-1.115-0.898    c-0.433-0.154-1.014-0.23-1.744-0.23H264.977z',
    ],
  },
  {
    name: 'SC',
    type: 'MapWhiteTG',
    data: [
      'M483.742,233.264l-0.792,0.635c-0.209-0.218-0.399-0.381-0.569-0.488c-0.17-0.105-0.416-0.159-0.736-0.159    c-0.352,0-0.637,0.079-0.854,0.237c-0.219,0.16-0.328,0.365-0.328,0.615c0,0.217,0.096,0.408,0.286,0.575    c0.189,0.167,0.516,0.349,0.976,0.546c0.459,0.197,0.816,0.381,1.073,0.551c0.256,0.172,0.466,0.359,0.63,0.564    c0.165,0.207,0.287,0.424,0.367,0.652c0.081,0.229,0.121,0.47,0.121,0.725c0,0.596-0.217,1.102-0.649,1.521    c-0.433,0.418-0.956,0.629-1.569,0.629c-0.601,0-1.13-0.164-1.589-0.491s-0.813-0.819-1.06-1.474l0.977-0.277    c0.356,0.808,0.902,1.211,1.64,1.211c0.355,0,0.651-0.105,0.89-0.318c0.236-0.213,0.354-0.482,0.354-0.807    c0-0.193-0.063-0.389-0.188-0.585c-0.124-0.195-0.278-0.356-0.463-0.483s-0.478-0.273-0.877-0.439s-0.708-0.317-0.925-0.454    c-0.216-0.138-0.402-0.3-0.562-0.488s-0.272-0.377-0.348-0.563c-0.072-0.188-0.109-0.388-0.109-0.602    c0-0.513,0.206-0.949,0.618-1.311s0.912-0.543,1.501-0.543c0.39,0,0.784,0.084,1.187,0.254    C483.143,232.662,483.477,232.92,483.742,233.264z',
      'M490.906,232.711v1.109c-0.771-0.381-1.479-0.57-2.128-0.57c-0.829,0-1.528,0.269-2.098,0.807    c-0.569,0.537-0.854,1.197-0.854,1.98c0,0.799,0.291,1.465,0.873,1.998c0.58,0.533,1.31,0.801,2.186,0.801    c0.635,0,1.309-0.2,2.02-0.602v1.116c-0.646,0.345-1.35,0.517-2.104,0.517c-1.107,0-2.055-0.375-2.841-1.127    c-0.786-0.75-1.18-1.654-1.18-2.715c0-1.039,0.388-1.931,1.159-2.673c0.772-0.739,1.704-1.11,2.793-1.11    C489.434,232.242,490.158,232.398,490.906,232.711z',
    ],
  },
  {
    name: 'NC',
    type: 'MapWhiteTG',
    data: [
      'M491.938,204.563l5.297,5.434v-5.151h0.978v7.483l-5.297-5.41v5.083h-0.978V204.563z',
      'M505.451,205.086v1.109c-0.771-0.381-1.479-0.57-2.126-0.57c-0.831,0-1.53,0.269-2.1,0.807    c-0.567,0.537-0.854,1.197-0.854,1.98c0,0.799,0.291,1.465,0.873,1.998s1.311,0.801,2.186,0.801c0.636,0,1.31-0.2,2.021-0.602    v1.116c-0.646,0.345-1.35,0.517-2.105,0.517c-1.106,0-2.055-0.375-2.84-1.127c-0.786-0.75-1.18-1.654-1.18-2.715    c0-1.039,0.387-1.931,1.159-2.673c0.772-0.739,1.703-1.11,2.793-1.11C503.979,204.617,504.703,204.773,505.451,205.086z',
    ],
  },
  {
    name: 'MT',
    type: 'MapWhiteTG',
    data: [
      'M175.887,54.297l1.957,7.453h-0.955l-1.189-4.562l-2.032,4.835l-2.041-4.856l-1.199,4.583h-0.952l1.949-7.453l2.24,5.329    L175.887,54.297z',
      'M178.429,54.594h4.383v0.977h-1.711v6.18h-0.969v-6.18h-1.703V54.594z',
    ],
  },
  {
    name: 'ID',
    type: 'g',
    data: [
      'M114.977,90.844h0.969V98h-0.969V90.844z',
      'M117.529,90.844h1.734c1.374,0,2.396,0.363,3.065,1.09c0.669,0.727,1.005,1.556,1.005,2.488    c0,0.94-0.346,1.771-1.039,2.494c-0.693,0.723-1.699,1.084-3.02,1.084h-1.746L117.529,90.844L117.529,90.844z M118.506,91.797    v5.234h0.056c0.727,0,1.273-0.033,1.641-0.098c0.367-0.065,0.715-0.208,1.045-0.43c0.33-0.222,0.592-0.514,0.787-0.879    c0.194-0.365,0.292-0.767,0.292-1.205c0-0.551-0.155-1.049-0.466-1.494s-0.683-0.745-1.115-0.898    c-0.433-0.154-1.014-0.23-1.745-0.23H118.506L118.506,91.797z',
    ],
  },
  {
    name: 'WY',
    type: 'MapWhiteTG',
    data: [
      'M185.988,111.821l2.301,5.291l2.159-5.002h1.013l-3.168,7.367l-2.307-5.304l-2.303,5.304l-3.168-7.367h1.009l2.171,5.05    L185.988,111.821z',
      'M192.071,112.094h1.081l1.754,2.892l1.746-2.892h1.075l-2.344,3.883v3.273h-0.977v-3.266L192.071,112.094z',
    ],
  },
  {
    name: 'CO',
    type: 'MapWhiteTG',
    data: [
      'M203.031,171.086v1.109c-0.771-0.38-1.479-0.57-2.126-0.57c-0.831,0-1.53,0.269-2.099,0.807    c-0.569,0.537-0.853,1.198-0.853,1.98c0,0.798,0.291,1.464,0.873,1.998c0.582,0.534,1.31,0.801,2.186,0.801    c0.636,0,1.309-0.2,2.02-0.602v1.117c-0.647,0.344-1.349,0.516-2.105,0.516c-1.108,0-2.055-0.375-2.841-1.126    c-0.786-0.75-1.179-1.655-1.179-2.715c0-1.04,0.386-1.931,1.159-2.672c0.773-0.741,1.704-1.112,2.793-1.112    C201.559,170.617,202.283,170.774,203.031,171.086z',
      'M203.9,174.379c0-1.033,0.389-1.918,1.167-2.655c0.778-0.738,1.713-1.106,2.805-1.106c1.066,0,1.984,0.372,2.755,1.115    c0.771,0.743,1.156,1.625,1.156,2.647c0,1.082-0.381,1.996-1.142,2.743c-0.761,0.747-1.695,1.12-2.803,1.12    c-1.092,0-2.021-0.376-2.788-1.128C204.283,176.362,203.9,175.45,203.9,174.379z M210.743,174.424c0-0.79-0.284-1.455-0.853-1.996    c-0.568-0.541-1.267-0.811-2.097-0.811c-0.789,0-1.465,0.275-2.027,0.825c-0.563,0.55-0.844,1.21-0.844,1.981    c0,0.782,0.289,1.443,0.866,1.984c0.577,0.541,1.285,0.811,2.123,0.811c0.77,0,1.435-0.276,1.993-0.828    C210.464,175.839,210.743,175.183,210.743,174.424z',
    ],
  },
  {
    name: 'IN',
    type: 'MapWhiteTG',
    data: [
      'M406.727,153.844h0.97V161h-0.97V153.844z',
      'M409.209,153.563l5.297,5.434v-5.152h0.977v7.484l-5.297-5.411v5.083h-0.977V153.563z',
    ],
  },
  {
    name: 'OH',
    type: 'MapWhiteTG',
    data: [
      'M439.413,149.669c0-1.033,0.389-1.918,1.167-2.655c0.776-0.738,1.713-1.106,2.805-1.106c1.066,0,1.984,0.372,2.756,1.115    c0.771,0.743,1.155,1.625,1.155,2.647c0,1.082-0.381,1.996-1.143,2.743c-0.762,0.747-1.694,1.12-2.803,1.12    c-1.092,0-2.021-0.376-2.788-1.128C439.796,151.653,439.413,150.74,439.413,149.669z M446.257,149.714    c0-0.79-0.284-1.455-0.854-1.996c-0.568-0.541-1.268-0.811-2.097-0.811c-0.789,0-1.465,0.275-2.026,0.825    c-0.563,0.55-0.845,1.21-0.845,1.981c0,0.782,0.288,1.443,0.866,1.984s1.285,0.811,2.123,0.811c0.77,0,1.434-0.276,1.992-0.828    C445.978,151.129,446.257,150.473,446.257,149.714z',
      'M448.607,146.134h0.978v2.75h3.414v-2.75h0.969v7.156h-0.969v-3.438h-3.414v3.438h-0.978V146.134z',
    ],
  },
  {
    name: 'NY',
    type: 'MapWhiteTG',
    data: [
      'M513.938,93.459l5.297,5.434V93.74h0.978v7.484l-5.297-5.411v5.083h-0.978V93.459z',
      'M521.24,93.74h1.081l1.755,2.892l1.745-2.892h1.074l-2.344,3.883v3.273h-0.977V97.63L521.24,93.74z',
    ],
  },
  {
    name: 'NH',
    type: 'g',
    data: [
      'M531.188,38.813l5.297,5.434v-5.152h0.978v7.484l-5.297-5.411v5.083h-0.978V38.813z',
      'M538.951,39.094h0.977v2.75h3.414v-2.75h0.969v7.156h-0.969v-3.438h-3.414v3.438h-0.977V39.094z',
    ],
  },
  {
    name: 'ME',
    type: 'MapWhiteTG',
    data: [
      'M566.356,47.577l1.957,7.453h-0.954l-1.189-4.562l-2.031,4.835l-2.041-4.856l-1.198,4.583h-0.951l1.948-7.453l2.24,5.329    L566.356,47.577z',
      'M569.258,47.874h3.961v0.977h-2.992v1.781h2.992v0.969h-2.992v2.461h2.992v0.969h-3.961V47.874z',
    ],
  },
  {
    name: 'WV',
    type: 'MapWhiteTG',
    data: [
      'M466.428,169.881l2.301,5.291l2.159-5.002h1.013l-3.168,7.367l-2.307-5.304l-2.303,5.304l-3.168-7.367h1.009l2.171,5.05    L466.428,169.881z',
      'M472.338,170.17h1.031l2.239,5.072l2.271-5.072h1.036l-3.316,7.383L472.338,170.17z',
    ],
  },
  {
    name: 'GA',
    type: 'MapWhiteTG',
    data: [
      'M456.156,253.734l-0.726,0.711c-0.729-0.713-1.574-1.069-2.532-1.069c-0.838,0-1.536,0.271-2.094,0.809    c-0.558,0.539-0.837,1.214-0.837,2.021c0,0.708,0.271,1.346,0.813,1.913c0.541,0.566,1.287,0.851,2.238,0.851    c1.428,0,2.324-0.622,2.691-1.866h-1.93v-0.978h3.047c0,0.491-0.033,0.883-0.1,1.175c-0.065,0.292-0.201,0.614-0.406,0.967    c-0.205,0.354-0.479,0.668-0.822,0.947s-0.71,0.479-1.102,0.6c-0.392,0.118-0.857,0.18-1.4,0.18c-1.235,0-2.223-0.389-2.957-1.166    c-0.735-0.775-1.104-1.664-1.104-2.663c0-1.052,0.376-1.948,1.128-2.687c0.753-0.74,1.664-1.109,2.734-1.109    c0.636,0,1.217,0.096,1.742,0.289C455.068,252.848,455.606,253.208,456.156,253.734z',
      'M460.784,252.32l3.379,7.431h-1.019l-0.835-1.836h-3.033l-0.823,1.836h-1.018L460.784,252.32z M460.784,254.558    l-1.144,2.544h2.299L460.784,254.558z',
    ],
  },
  {
    name: 'WI',
    type: 'MapWhiteTG',
    data: [
      'M367.988,97.071l2.301,5.291l2.158-5.002h1.014l-3.168,7.367l-2.307-5.304l-2.304,5.304l-3.168-7.367h1.009l2.172,5.05    L367.988,97.071z',
      'M374.477,97.344h0.969v7.156h-0.969V97.344z',
    ],
  },
  {
    name: 'PA',
    type: 'MapWhiteTG',
    data: [
      'M494.477,128.844h1.438c0.812,0,1.467,0.191,1.965,0.573c0.497,0.382,0.746,0.877,0.746,1.488    c0,0.614-0.264,1.128-0.788,1.544c-0.525,0.416-1.322,0.618-2.392,0.606V136h-0.97V128.844z M495.446,129.696v2.508    c0.731-0.007,1.273-0.111,1.627-0.312c0.353-0.201,0.528-0.522,0.528-0.964c0-0.333-0.134-0.622-0.399-0.866    C496.934,129.818,496.349,129.696,495.446,129.696z',
      'M501.747,128.571l3.379,7.43h-1.019l-0.835-1.836h-3.032l-0.824,1.836h-1.017L501.747,128.571z M501.746,130.808    l-1.143,2.544h2.3L501.746,130.808z',
    ],
  },
  {
    name: 'DE',
    type: 'g',
    data: [
      'M541.383,150.219h1.734c1.374,0,2.396,0.363,3.064,1.09c0.669,0.727,1.005,1.556,1.005,2.488    c0,0.94-0.346,1.771-1.039,2.494c-0.69,0.723-1.698,1.084-3.02,1.084h-1.746L541.383,150.219L541.383,150.219z M542.359,151.172    v5.234h0.058c0.727,0,1.272-0.033,1.641-0.098c0.366-0.065,0.715-0.208,1.045-0.43c0.329-0.221,0.592-0.514,0.786-0.879    c0.194-0.365,0.292-0.767,0.292-1.205c0-0.551-0.155-1.049-0.467-1.494c-0.311-0.445-0.682-0.745-1.115-0.898    c-0.432-0.154-1.014-0.23-1.744-0.23H542.359z',
      'M548.243,150.219h3.961v0.977h-2.992v1.781h2.992v0.969h-2.992v2.461h2.992v0.969h-3.961V150.219z',
    ],
  },
  {
    name: 'MA',
    type: 'g',
    data: [
      'M579.512,82.547L581.469,90h-0.955l-1.188-4.562l-2.031,4.835l-2.041-4.856L574.054,90h-0.951l1.949-7.453l2.24,5.329    L579.512,82.547z',
      'M585.159,82.571l3.379,7.43h-1.019l-0.835-1.836h-3.033l-0.823,1.836h-1.018L585.159,82.571z M585.158,84.808    l-1.143,2.544h2.3L585.158,84.808z',
    ],
  },
  {
    name: 'CT',
    type: 'g',
    data: [
      'M566.281,118.586v1.109c-0.771-0.38-1.479-0.57-2.128-0.57c-0.829,0-1.528,0.269-2.098,0.807    c-0.568,0.537-0.854,1.198-0.854,1.98c0,0.798,0.291,1.464,0.873,1.998c0.58,0.534,1.31,0.801,2.186,0.801    c0.635,0,1.309-0.2,2.02-0.602v1.117c-0.646,0.344-1.35,0.516-2.104,0.516c-1.107,0-2.055-0.375-2.841-1.126    c-0.786-0.75-1.18-1.655-1.18-2.715c0-1.04,0.388-1.931,1.159-2.672c0.772-0.741,1.704-1.112,2.793-1.112    C564.809,118.117,565.533,118.274,566.281,118.586z',
      'M567.009,118.344h4.383v0.977h-1.711v6.18h-0.969v-6.18h-1.703V118.344z',
    ],
  },
  {
    name: 'RI',
    type: 'g',
    data: [
      'M574.898,109.969h1.552c0.771,0,1.399,0.192,1.886,0.575c0.485,0.384,0.728,0.875,0.728,1.473    c0,0.479-0.155,0.902-0.471,1.269c-0.313,0.367-0.803,0.625-1.467,0.774l2.078,3.065h-1.121l-1.988-2.945h-0.219v2.945h-0.977    L574.898,109.969L574.898,109.969z M575.875,110.836v2.492c1.441,0.03,2.164-0.387,2.164-1.252c0-0.303-0.084-0.551-0.253-0.744    c-0.169-0.192-0.395-0.324-0.676-0.393C576.83,110.871,576.418,110.836,575.875,110.836z',
      'M580.477,109.969h0.97v7.156h-0.97V109.969z',
    ],
  },
  {
    name: 'AR',
    type: 'MapWhiteTG',
    data: [
      'M344.418,233.07l3.379,7.431h-1.018l-0.836-1.836h-3.033l-0.824,1.836h-1.016L344.418,233.07z M344.418,235.308    l-1.143,2.544h2.299L344.418,235.308z',
      'M348.845,233.344h1.552c0.771,0,1.399,0.191,1.885,0.575c0.484,0.384,0.729,0.874,0.729,1.474    c0,0.479-0.157,0.901-0.471,1.268c-0.313,0.367-0.803,0.625-1.469,0.775l2.08,3.064h-1.121l-1.988-2.945h-0.219v2.945h-0.978    V233.344z M349.82,234.211v2.492c1.442,0.029,2.164-0.387,2.164-1.252c0-0.303-0.084-0.551-0.252-0.744    c-0.17-0.191-0.395-0.323-0.676-0.393C350.777,234.246,350.365,234.211,349.82,234.211z',
    ],
  },
  {
    name: 'KY',
    type: 'MapWhiteTG',
    data: [
      'M426.25,187.344h0.977v2.909l3.022-2.909h1.368l-3.452,3.322l3.476,3.834h-1.251l-3.163-3.489v3.489h-0.977V187.344    L426.25,187.344z',
      'M432.166,187.344h1.08l1.754,2.892l1.746-2.892h1.075l-2.344,3.883v3.273h-0.977v-3.266L432.166,187.344z',
    ],
  },
  {
    name: 'NJ',
    type: 'g',
    data: [
      'M544.563,133.563l5.297,5.434v-5.152h0.978v7.484l-5.297-5.411v5.083h-0.978V133.563z',
      'M553.959,133.86h0.977v5.244c0,1.426-0.594,2.139-1.78,2.139c-0.411,0-0.817-0.107-1.22-0.32l0.483-0.797    c0.314,0.109,0.57,0.164,0.771,0.164c0.515,0,0.771-0.344,0.771-1.033L553.959,133.86L553.959,133.86z',
    ],
  },
  {
    name: 'MD',
    type: 'g',
    data: [
      'M545.887,166.672l1.957,7.453h-0.954l-1.189-4.562l-2.031,4.835l-2.041-4.856l-1.198,4.583h-0.952l1.949-7.453l2.24,5.329    L545.887,166.672z',
      'M548.874,166.969h1.733c1.374,0,2.396,0.363,3.065,1.09c0.669,0.727,1.005,1.556,1.005,2.488    c0,0.94-0.347,1.771-1.038,2.494c-0.693,0.723-1.699,1.084-3.021,1.084h-1.746L548.874,166.969L548.874,166.969z M549.851,167.922    v5.234h0.057c0.727,0,1.272-0.033,1.641-0.098s0.716-0.208,1.045-0.43c0.33-0.221,0.592-0.514,0.787-0.879    c0.194-0.365,0.292-0.767,0.292-1.205c0-0.551-0.155-1.049-0.466-1.494c-0.313-0.445-0.685-0.745-1.115-0.898    c-0.434-0.154-1.015-0.23-1.745-0.23H549.851z',
    ],
  },
];
