import { useMemo } from 'react';
import { LegalMapStatesData } from 'helpers/constants/legal-map/legal-map-states-data';
import { DataProps } from 'helpers/types/molecules/legal-map/legal-map';
import { buildElement } from 'helpers/utils/legal-map';
import {
  LimitedPath,
  LimitedPolyline,
  MedicalG,
  MedicalPath,
  MedicalPolyline,
  NotLegalPath,
  RecreationalG,
  RecreationalPath,
  RecreationalPolyline,
} from './styles';

interface StateProps {
  styles?: string[];
  statesData?: DataProps[];
  location?: string;
}

const States = ({ styles = [], statesData = LegalMapStatesData, location = '' }: StateProps) => {
  const stateMap = useMemo(
    () =>
      statesData &&
      statesData?.map((state) => {
        switch (state.type) {
          case 'RecreationalG':
            return buildElement({ state, Element: RecreationalG });
          case 'RecreationalPath':
            return buildElement({ state, Element: RecreationalPath });
          case 'RecreationalPolyline':
            return buildElement({ state, Element: RecreationalPolyline, isPoints: true });
          case 'MedicalPath':
            return buildElement({ state, Element: MedicalPath });
          case 'MedicalG':
            return buildElement({ state, Element: MedicalG });
          case 'MedicalPolyline':
            return buildElement({ state, Element: MedicalPolyline, isPoints: true });
          case 'LimitedPath':
            return buildElement({ state, Element: LimitedPath });
          case 'LimitedPolyline':
            return buildElement({ state, Element: LimitedPolyline, isPoints: true });
          case 'NotLegalPath':
            return buildElement({ state, Element: NotLegalPath });
          case 'RiseStateG':
            return buildElement({ state, Element: styles[0], location });
          case 'RiseStatePath':
            return buildElement({ state, Element: styles[1], location });
          case 'RiseStatePolyline':
            return buildElement({ state, Element: styles[2], isPoints: true, location });
          case 'NonRiseStatePath':
            return buildElement({ state, Element: styles[3] });
          case 'NonRiseStateG':
            return buildElement({ state, Element: styles[4] });
          case 'NonRiseStatePolyline':
            return buildElement({ state, Element: styles[5], isPoints: true });
          default:
            return null;
        }
      }),
    [statesData, location],
  );

  return <>{stateMap}</>;
};

export default States;
