import { BuildFunctionProps } from 'helpers/types/molecules/legal-map/legal-map';

export const buildElement = ({ state, Element, isPoints = false, location }: BuildFunctionProps) => {
  const { data, name } = state;
  if (Array.isArray(data)) {
    const paths = data.map((d, i) => {
      return <path id={name} d={d} key={`${name}-${i}`} data-testid={`paths-${name}`} />;
    });
    return (
      <Element id={name} key={name} $hover={location === name} data-testid={`g-${name}`}>
        {paths}
      </Element>
    );
  }
  const d = isPoints ? { points: data } : { d: data };
  return <Element id={name} {...d} key={name} $hover={location === name} data-testid={name} />;
};
