export const LegalMapKaysData = {
  title: 'Key:',
  data: [
    {
      color: 'bg-leaf',
      title: 'Recreational',
    },
    {
      color: 'bg-kief',
      title: 'Full Medical Use',
    },
    {
      color: 'bg-kief-900',
      title: 'Limited Medical Use',
      subTitle: '(CBD-Onlv/Low-THC program)',
    },
    {
      color: 'bg-grey-200',
      title: 'Not Legal',
    },
  ],
};
