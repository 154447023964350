import { Typography } from 'components/atoms/typography';
import { TKeyProps } from 'helpers/types/molecules/legal-map/legal-map';
import { KeyBox, KeyCard, KeyContainerFlex, KeyStyles } from './styles';

const MedicalKey = ({ title, data }: TKeyProps) => {
  return (
    <KeyCard>
      {title && <Typography variant="h5">{title}</Typography>}
      <KeyContainerFlex>
        {data &&
          data?.map((item, index) => (
            <KeyBox key={index}>
              <KeyStyles $style={item?.color} />
              {(item.title || item.subTitle) && (
                <div>
                  {item?.title && (
                    <Typography variant="body-small-bold" className="!text-grey-500">
                      {item?.title}
                    </Typography>
                  )}
                  {item?.subTitle && (
                    <Typography variant="body-small" className="!text-grey-500">
                      {item.subTitle}
                    </Typography>
                  )}
                </div>
              )}
            </KeyBox>
          ))}
      </KeyContainerFlex>
    </KeyCard>
  );
};

export default MedicalKey;
