import tw from 'tailwind-styled-components';
type TKeyStyles = {
  $style?: string;
};

export const KeyCard = tw.div`
    flex 
    flex-col 
    gap-8
    md:gap-5 
    2xl:gap-6
`;
export const KeyContainerFlex = tw.div`
    w-full
    md:flex-row
    flex-col
    flex
    gap-3
    md:gap-0
`;

export const KeyBox = tw.div`
    flex 
    flex-col 
    items-start 
    gap-3 
    w-full 
    md:max-w-[185px] 
    2xl:max-w-[298px]
`;

export const KeyStyles = tw.div<TKeyStyles>`
  sm:block
  md:inline-block
  2xl:h-8
  2xl:w-8
  md:h-7
  md:w-7
  h-6
  w-6
  border-0
  md:max-w-[185px]
  2xl:max-w-[298px]
  ${(p) => (p.$style ? p.$style : '')}
`;
